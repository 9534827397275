@font-face {
  font-family: "Sofia Pro";
  src: local("Sofia Pro"),
    url("./assets/fonts/SofiaPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/SofiaPro-Regular.woff") format("woff");

  font-weight: normal;
}

:root {
  --with-fallback-safe-area-inset-top: max(
    env(safe-area-inset-top, 24px),
    24px
  );
  --with-fallback-safe-area-inset-bottom: max(
    env(safe-area-inset-bottom, 24px),
    24px
  );
  --with-fallback-safe-area-inset-right: max(
    env(safe-area-inset-right, 12px),
    12px
  );
  --with-fallback-safe-area-inset-left: max(
    env(safe-area-inset-left, 12px),
    12px
  );
}

html,
body {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Sofia Pro", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
