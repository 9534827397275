.custom-popup .mapboxgl-popup-content,
.mapbox-hover-tooltip .mapboxgl-popup-content {
  padding: 24px;
  border-radius: 8px;
  margin-top: 16px;
}

.custom-popup > .mapboxgl-popup-content > .mapboxgl-popup-close-button {
  height: 24px;
  width: 24px;
  background-color: #979797;
  color: white;
  border-radius: 50%;
  top: 12px;
  right: 12px;
}

.mapbox-hover-tooltip .mapboxgl-popup-content {
  background-color: #18a0fb;
  padding: 16px;
}

.mapbox-hover-tooltip.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapbox-hover-tooltip.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapbox-hover-tooltip.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: #18a0fb;
}

.mapbox-hover-tooltip.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapbox-hover-tooltip.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapbox-hover-tooltip.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #18a0fb;
}

.mapbox-hover-tooltip.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #18a0fb;
}

.mapbox-hover-tooltip.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #18a0fb;
}

.mapboxgl-ctrl-geocoder--input {
  height: 56px;
  padding-left: 9px;
  font-family: "Sofia Pro";
}

.mapboxgl-ctrl-geocoder--icon {
  position: unset;
  margin-left: 16.5px;
}

.mapboxgl-ctrl-geocoder {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: none;
  width: 330px;
}

.mapboxgl-ctrl-geocoder:hover {
  border: 1px solid rgb(0, 0, 0);
}

.mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background: none;
}

@media (max-width: 1200px) {
  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: inherit;
  }
}
