// NOTE: the ant design UI library for some reason includes global non-namespaced reset styles. There is an alias in the craco config that points to this file that namespaces the problematic styles.
// @see https://github.com/ant-design/ant-design/issues/4331
// @see https://github.com/ant-design/ant-design/issues/9363
@import "~antd/es/style/themes/default.less";
@import "~antd/es/style/mixins/index.less";
@import "~antd/es/style/core/base.less";
@import "~antd/es/style/core/iconfont.less";
@import "~antd/es/style/core/motion.less";
@import "~antd/es/upload/style/index.less";

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ant-input[type="search"]::-webkit-search-cancel-button,
.ant-input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.ant-table-tbody > .ant-table-row > .ant-table-cell {
  cursor: pointer;
}

@primary-color: #18A0FB;@error-color: #D32F2F;@success-color: #9FEDF5;@disabled-color: rgba(0, 0, 0, 0.38);@text-color: rgba(0, 0, 0, 0.87);